'use client';

import { Spinner } from '../components/Spinner/Spinner';

export default function Loading(): React.ReactNode {
    return (
        <div>
            <Spinner />
        </div>
    );
}
